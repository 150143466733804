import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection2() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <div className=" bg-white w-full h-auto mx-auto flex flex-wrap justify-center lg:pt-48 pt-32 md:pb-20 pb-10">
                <div className=" relative w-4/5 mx-auto justify-center flex items-center" >
                    <div className="lg:w-[600px] lg:h-[600px] md:w-[400px] md:h-[400px] w-[300px] h-[300px] rounded-full border-[5px] border-white shadow-xl z-10" style={{ background: `url("${rpdata?.stock?.[0]}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                    <div className="absolute md:flex hidden top-[2%] right-[15%] borderColor border-[45px] h-[250px] w-[250px] rounded-full">
                        <div className="absolute bg-center rounded-full top-[160%] -right-[25%] h-[280px] w-[280px] shadow-xl z-10 " style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/videos-a7d6e.appspot.com/o/Recurso%201.png?alt=media&token=fb39cf11-5fdd-492a-8c61-5e29a07b66b3")`, backgroundSize: "cover" }}></div>
                    </div>
                    <div className="absolute bg1 md:h-[168px] md:w-[168px] w-[83px] h-[83px] rounded-full md:left-[14%] left-0 bottom-[2%]"></div>
                </div>
                <div className="w-4/5 mx-auto justify-center flex items-center md:py-10 py-5">
                    <div className="w-full flex flex-col justify-center items-center">
                        <h1 className="text-center">
                            {rpdata?.dbSlogan?.[0].slogan}
                        </h1>
                        <p className="text-center">{rpdata?.dbValues?.[1].description}</p>
                        <div className="flex justify-center md:block">
                            <ButtonContent />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroSection2;